<template>
  <div>
    <div v-if="!loading" class="main" :style="style">
      <Header title="Publicar" titleColor="white" />
      <section class="content">
        <div v-if="!notFound.show">
          <v-row>
            <v-col cols="11" md="5">
              <v-row>
                <v-col cols="12">
                  <div class="card ml-5 pa-6">
                    <!-- show center name and description -->

                    <div v-if="!editDescription.open">
                      <p class="text-h6">
                        {{ info.centro }}
                        <span
                          @click="handleEditDescription"
                          class="edit-des"
                          ><v-icon> mdi-pencil </v-icon></span
                        >
                      </p>
                      <p v-if="info.description == ''">
                        Não há mensagem cadastrada.
                      </p>
                      <p>{{ info.description }}</p>
                    </div>

                    <!-- edit description -->
                    <div v-if="editDescription.open" class="mt-6">
                      <v-text-field
                        v-model="editDescription.placeholder"
                        label="Nova Mensagem"
                        maxlength="280"
                        :rules="[
                          editDescription.rules.required,
                          editDescription.rules.counter,
                        ]"
                        counter
                        outlined
                      ></v-text-field>
                      <div>
                        <v-icon
                          @click="editDescription.open = false"
                          class="ma-2"
                        >
                          mdi-arrow-left
                        </v-icon>
                        <v-icon
                          @click="saveEditDescription"
                          class="c-b ma-2"
                        >
                          mdi-checkbox-marked-circle
                        </v-icon>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <div
                    v-if="!editDescription.open"
                    class="card ml-5 pt-3 pl-3"
                  >
                    <a :href="info.link" target="_blank" class="link">
                      https://sa.ci.ufpb.br{{ info.link }}</a
                    >
                    <v-icon
                      class="ml-3 mb-1 cursor"
                      v-clipboard:copy="
                        `https://sa.ci.ufpb.br${info.link}`
                      "
                      @click="copyURL"
                    >
                      mdi-content-copy
                    </v-icon>
                    <v-icon v-if="copy" class="ml-n4 mb-1 check">
                      mdi-check-bold
                    </v-icon>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="5" md="3">
              <div class="card ml-5 py-6 qr">
                <div>
                  <qrcode-vue
                    :value="`https://sa.ci.ufpb.br${info.link}`"
                    :size="142"
                    level="H"
                  ></qrcode-vue>
                </div>
              </div>
            </v-col>

            <!-- DISPLAY PRINT BUTTON -->
            <v-col cols="12" md="4" class="pr-11">
              <v-row>
                <v-col
                  cols="12"
                  class="cursor"
                  @click="generateReport"
                >
                  <div class="card ml-5 pa-5">
                    <v-row>
                      <v-col cols="1">
                        <v-icon class="mb-1 icon">
                          mdi-printer
                        </v-icon>
                      </v-col>
                      <v-col cols="11">
                        <p class="text-button">
                          Imprimir Identificação de Salas
                        </p>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>

                <!-- DISPLAY DELETE BUTTON -->
                <v-col
                  cols="6"
                  class="pt-0 cursor"
                  @click="openDelete"
                >
                  <div class="card ml-5 pa-5">
                    <v-row>
                      <v-col cols="1">
                        <v-icon class="mb-1 icon">
                          mdi-delete
                        </v-icon>
                      </v-col>
                      <v-col cols="10">
                        <p class="text-button ml-3">Remover</p>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>

                <!-- DISPLAY EDIT BUTTON -->
                <v-col
                  cols="6"
                  class="pt-0 cursor"
                  @click="openEditar"
                >
                  <div class="card ml-5 pa-5">
                    <v-row>
                      <v-col cols="1">
                        <v-icon class="mb-1 icon">
                          mdi-pencil
                        </v-icon>
                      </v-col>
                      <v-col cols="10">
                        <p class="text-button ml-3">Editar</p>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-col>

            <v-dialog
              v-model="dialog.open"
              persistent
              max-width="420"
            >
              <v-card>
                <v-card-title class="text-h5">
                  {{ dialog.title }}
                </v-card-title>
                <v-card-text> {{ dialog.msg }}</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="grey darken-1"
                    text
                    @click="dialog.open = false"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="handleConfirmar"
                  >
                    Confirmar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-col cols="12" class="pt-0">
              <div v-if="!notFound.show" class="solution">
                <div v-if="!edit.show">
                  <!-- DISPLAY SOLUTION -->
                  <p class="text-h6">Solução Alocada</p>
                  <p v-if="notPublished">
                    Você ainda não publicou uma alocação.
                  </p>
                  <PaasSolution
                    :key="componentKey"
                    v-if="!loading && !notPublished"
                    :plan="solution"
                    occupancyRate
                  />
                </div>

                <div v-if="edit.show">
                  <EditSolution
                    :solution="solution"
                    :back="() => (edit.show = false)"
                    :save="handleEditPublicSolution"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div v-if="notFound.show" class="solution pt-16">
          <NotFound
            class="pt-16"
            :title="notFound.title"
            :text="notFound.text"
          />
        </div>
      </section>
    </div>
    <Loading v-if="loading" />
  </div>
</template>

<script>
import PaasSolution from "@/components/paas/PaasSolution.vue";
import Header from "@/components/Header.vue";
import Loading from "@/components/Loading.vue";
import NotFound from "@/components/NotFound.vue";
import EditSolution from "@/components/paas/EditSolution.vue";

import QrcodeVue from "qrcode.vue";

import { getPublicSolution } from "@/api/paas";
import {
  editDescriptionPaasPublic,
  handleRemovePaasSolutionPublic,
  editPaasPublic,
} from "@/api/db";

export default {
  name: "PaasPublicar",
  components: {
    Header,
    PaasSolution,
    QrcodeVue,
    Loading,
    NotFound,
    EditSolution,
  },
  data() {
    return {
      componentKey: 0,
      solution: [],
      info: {
        id: "",
        centro: "",
        date: "",
        description: "",
        link: "",
      },
      dialog: {
        open: false,
        title: "",
        msg: "",
        clicked: "",
      },
      loading: true,
      found: false,
      notPublished: false,
      notFound: {
        show: false,
        title: "Eita! Parece que não há uma alocação pública.",
        text: "Tente simular uma alocação. Após o resultado, clique em publicar solução.",
      },
      copy: false,
      edit: {
        show: false,
      },
      editDescription: {
        open: false,
        placeholder: "",
        rules: {
          required: (value) => !!value || "Digite algo.",
          counter: (value) =>
            value.length <= 280 || "Max 280 caracteres",
        },
      },
    };
  },
  computed: {
    style() {
      return `${
        this.$vuetify.breakpoint.width > 1920
          ? `width: ${Math.floor(
              1920 + (this.$vuetify.breakpoint.width - 1920) / 2
            )}px;`
          : "width: 100%;"
      }`;
    },
  },
  created() {
    this.$store.commit("setCurrentComponent", {
      component: "Paas",
    });
  },

  methods: {
    openDelete() {
      if (!this.notPublished) {
        this.dialog = {
          title: "Tem certeza que quer excluir esta solução?",
          msg: "Todas as alterações que foram feitas nesta solução serão removidas",
          clicked: "delete",
          open: true,
        };
      }
    },
    handleConfirmar() {
      switch (this.dialog.clicked) {
        case "delete":
          handleRemovePaasSolutionPublic();
          this.dialog.open = false;
          setTimeout(() => {
            this.init();
          }, 1500);
          break;
      }
    },
    handleEditDescription() {
      this.editDescription.placeholder = this.info.description;
      this.editDescription.open = true;
    },
    async saveEditDescription() {
      if (this.editDescription.placeholder.length <= 280) {
        await editDescriptionPaasPublic(
          this.editDescription.placeholder
        );
        this.info.description = this.editDescription.placeholder;
        this.editDescription.open = false;
      }
    },
    handleEditPublicSolution(salas, turmas) {
      editPaasPublic(salas, turmas, this.solution.hash).then(() => {
        this.$router.go();
      });
    },
    copyURL() {
      this.copy = true;
      setTimeout(() => {
        this.copy = false;
      }, 3000);
    },
    async init() {
      let center = this.$store.getters.getCentro;

      this.info.link = `/salas/${center}`.toLocaleLowerCase();

      //get solution on database
      let res = await getPublicSolution(center, "");

      switch (res.status) {
        //if is published
        case "published":
          //save the solution
          this.solution = JSON.parse(JSON.stringify(res.solution));

          //close loading screem

          this.info.centro = res.centro;
          this.info.description = res.description;
          this.info.date = res.date;
          this.info.id = res.id;

          this.loading = false;
          this.found = true;
          break;
        //if academic center exists, but there is no solution
        case "not-published":
          this.info.centro = res.centro;
          this.info.description = res.description;
          this.info.date = res.date;
          this.info.id = res.id;

          this.loading = false;
          this.notPublished = true;
          break;
        //if academic center is not found
        default:
          this.loading = false;
          this.notFound.show = true;
          break;
      }

      this.componentKey += 1;
    },
    openEditar() {
      if (!this.notPublished) this.edit.show = true;
    },
    goHistorico() {
      this.$router.push({ path: "/paas-historico" });
    },
    generateReport() {
      if (!this.notPublished) {
        const routeData = this.$router.resolve({
          path: "/print-rooms",
        });
        window.open(routeData.href, "_blank");
      }
    },
  },

  async mounted() {
    await this.init();
  },
};
</script>

<style scoped>
.main {
  background: rgb(71, 118, 230);
  /* background: linear-gradient(50deg, rgba(71,118,230,1) 0%, rgba(142,84,233,1) 100%); */
  background: linear-gradient(135deg, #1491ff, #0346b6);
  min-height: 100vh;
}

.content {
  /* margin: auto; */
  max-width: 1600px;
  left: 0;
}

.solution {
  padding: 40px;
  border-radius: 15px;
  margin: 0 10px 0 20px;
  min-height: 90vh;
  background-color: white;
}

.title {
  margin-left: 15px;
  color: white;
}

.card {
  border-radius: 15px;
  background-color: white;
  width: 100%;
  min-height: 50px;
}

.qr {
  text-align: center;
}

.link {
  text-decoration: none;
}

.c-b {
  color: #1565c0;
}

.icon {
  font-size: 30px;
  color: #1565c0;
}

.cursor {
  cursor: pointer;
}

.check {
  color: green;
}

.edit-des {
  cursor: pointer;
}

.center {
  height: 200px;
}
</style>
