<template>
  <div class="px-md-9 px-2">
    <p class="text-h6">Ocupação</p>

    <p class="text-body-2 font-weight-bold">Escala de intensidade:</p>

    <SquareGrid />

    <div class="mb-10" />

    <v-row>
      <v-col :cols="12" :md="4">
        <HandleMatrix
          :matrix="matrix_rate"
          :rowHeader="matrix_headers_row"
          :colHeader="matrix_headers_col"
          :item="item"
          @click-item="handleClick"
        />
      </v-col>
      <v-col :cols="12" :md="8">
        <HandleTimeSummary
          v-if="timeSummary[0]"
          :title="timeSummary[1]"
          :max="max_value"
          :rate="timeSummary[2]"
          :classes="timeSummary[3]"
          @close="handleClick"
        />
        <HandleGeneralSummary
          v-else
          :chart1="chart1"
          :chart2="chart2"
          :max="max_value"
          :times="times_with_max_value"
        />
      </v-col>
    </v-row>

    <div class="mb-10" />
    <!-- <p>{{ matrix_rate }}</p> -->
  </div>
</template>

<script>
import SquareGrid from "@/components/paas/SquareGrid.vue";
import HandleMatrix from "@/handles/paas/HandleMatrix.vue";
import HandleGeneralSummary from "@/handles/paas/HandleGeneralSummary.vue";
import HandleTimeSummary from "@/handles/paas/HandleTimeSummary.vue";

export default {
  name: "HandleOcupacao",
  components: {
    SquareGrid,
    HandleMatrix,
    HandleGeneralSummary,
    HandleTimeSummary,
  },
  props: {
    plan: Object,
  },
  data() {
    return {
      matrix_search: [],
      matrix_rate: [],
      max_value: 0,
      times_with_max_value: [],
      matrix_headers_col: [],
      matrix_headers_row: [],
      item: [-1, -1],
      chart1: {},
      chart2: {},
    };
  },
  mounted() {
    this.createMatrix();
  },
  computed: {
    timeSummary() {
      let [i, j] = this.item;

      if (i == -1) return [false, null, null, null];

      let title = [
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
        "Domingo",
      ];

      return [
        true,
        `Resumo: ${title[j]}, ${this.matrix_headers_row[i]}`,
        this.matrix_rate[i][j],
        this.matrix_search[i][j],
      ];
    },
  },
  methods: {
    handleClick(i, j) {
      if (this.item[0] == i && this.item[1] == j)
        this.item = [-1, -1];
      else this.item = [i, j];
    },
    createChart() {
      let values = [];
      let values2 = [];
      let value2_temp = [
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
        [0, 0],
      ];

      this.matrix_rate.forEach((line, i) => {
        if (i >= 16) return;

        let count = 0;
        let sum = 0;

        line.forEach((e, j) => {
          if (e != 0) {
            count++;
            sum += e;

            value2_temp[j][0] += e;
            value2_temp[j][1] += 1;
          }
        });

        if (sum == 0) values.push(0);
        else values.push(Math.ceil((sum / count) * 100));
      });

      value2_temp.forEach((e) => {
        if (e[1] == 0) values2.push(0);
        else values2.push(Math.ceil((e[0] / e[1]) * 100));
      });

      this.chart1 = {
        labels: this.matrix_headers_row,
        datasets: values,
      };

      this.chart2 = {
        labels: this.matrix_headers_col,
        datasets: values2,
      };
    },
    createMatrix() {
      let time;
      let matrix = [];

      for (let i = 0; i < 18; i++) {
        time = [];
        for (let j = 0; j < 6; j++) {
          time.push([]);
        }
        matrix.push(time);
      }

      let matrix_ocp = JSON.parse(JSON.stringify(matrix));

      this.plan.solution.forEach((room, i) => {
        room.classes.forEach((discipline, j) => {
          discipline.horario.split(" ").forEach((horario) => {
            //converts UFPB time format to array
            //example '24T23' -> [[2,4], [8,9]]
            let timeMatrix = [[], []];
            let changeCol = 0;
            let turno = 0;

            for (let i = 0; i < horario.length; i++) {
              if (isNaN(Number(horario.charAt(i)))) {
                changeCol = 1;
                switch (horario.charAt(i).toUpperCase()) {
                  case "M":
                    turno = 0;
                    break;
                  case "T":
                    turno = 6;
                    break;
                  case "N":
                    turno = 12;
                    break;
                }
              } else
                timeMatrix[changeCol].push(
                  turno + Number(horario.charAt(i))
                );
            }

            //now add classes to main matrix
            timeMatrix[0].forEach((week_day) => {
              timeMatrix[1].forEach((time) => {
                matrix[time - 1][week_day - 2].push({
                  codigo: discipline.codigo,
                  nome: discipline.nome,
                  docente: discipline.docente,
                  sala: room.bloco + " " + room.nome,
                  id: discipline.codigo + "-" + discipline.turma,
                  j_idx: j,
                  i_idx: i,
                });
              });
            });
          });
        });
        //console.log(room.nome);
      });

      this.matrix_headers_col = [
        "Seg",
        "Ter",
        "Qua",
        "Qui",
        "Sex",
        "Sab",
      ];

      this.matrix_headers_row = [
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "19:50",
        "20:40",
        "21:30",
      ];

      let biggest = 0;

      matrix.forEach((day) => {
        day.forEach((h) => {
          biggest = Math.max(biggest, h.length);
        });
      });

      let times_max = [];

      matrix.forEach((time, i) => {
        time.forEach((day, j) => {
          let rate = day.length / biggest;
          if (rate == 1)
            times_max.push(
              `${this.matrix_headers_col[j]}, ${this.matrix_headers_row[i]}`
            );
          matrix_ocp[i][j] = rate;
        });
      });

      this.matrix_search = matrix;
      this.matrix_rate = matrix_ocp;
      this.max_value = biggest;
      this.times_with_max_value = times_max.sort();

      this.createChart();
    },
  },
};
</script>

<style></style>
