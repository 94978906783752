<template>
  <section class="main pt-6">
    <section>
      <p class="m-title">
        {{ roomSolution.bloco + " " + roomSolution.nome
        }}<span class="m-subtitle">
          {{ roomSolution.tipo }},
          {{ roomSolution.capacidade }} pessoas.</span
        >
      </p>
    </section>
    <v-container class="mb-10">
      <v-row>
        <v-col cols="1">
          <v-icon
            @click="handleLeft"
            x-large
            class="ml-xs-1 ml-md-4 mt-17"
            v-bind:class="[
              disableLeft ? 'icon-disable' : 'icon-enable',
            ]"
          >
            mdi-chevron-left
          </v-icon>
        </v-col>
        <v-col :cols="isMobile ? 2 : 1">
          <ColCalendar class="mt" type="time" />
        </v-col>
        <v-col
          :cols="isMobile ? 7 : 8 / columns"
          v-for="(day, idx) in display.slice(start, end)"
          :key="idx"
          class="mg-m px-0"
        >
          <ColCalendar
            type="day"
            :title="day.title"
            :data="day.data"
            :detail="handleDetail"
          />
        </v-col>

        <v-col cols="1">
          <v-icon
            @click="handleRight"
            x-large
            class="mt-17"
            v-bind:class="[
              disableRight ? 'icon-disable' : 'icon-enable',
            ]"
          >
            mdi-chevron-right
          </v-icon>
        </v-col>
      </v-row>
    </v-container>

    <div>
      <v-dialog
        v-model="detail.show"
        max-width="500"
        :overlay-opacity="0.7"
      >
        <div class="teste">
          <div class="div-title">
            <v-card-title class="detail-title">
              {{ detail.data.nome }}
            </v-card-title>
            <v-card-text>
              {{ detail.data.codigo }}
              <p>turma {{ detail.data.turma }}</p>
            </v-card-text>
          </div>
          <div class="div-content">
            <v-card-text>
              <p>{{ detail.data.docente }}</p>
              {{ detail.data.departamento }},
              <p>{{ detail.data.alunos }} alunos.</p>
              <p class="horario">{{ detail.data.horario }}</p>
            </v-card-text>
            <div class="ocupacao">
              <p class="ocupacao-taxa">
                {{
                  Math.round(
                    (detail.data.alunos / roomSolution.capacidade) *
                      100
                  ) + "%"
                }}
              </p>
              <p class="ocupacao-title">ocupação</p>
            </div>
          </div>
        </div>
      </v-dialog>
    </div>
  </section>
</template>

<script>
import ColCalendar from "./ColCalendar.vue";

export default {
  name: "CalendarView",
  components: {
    ColCalendar,
  },
  props: {
    roomSolution: Object,
  },
  data() {
    return {
      display2: [],
      display: [],
      start: 0,
      end: 0,
      columns: 0,
      disableLeft: true,
      disableRight: false,
      widthBackUp: 0,
      detail: {
        show: false,
        data: [],
      },
    };
  },
  methods: {
    handleDetail(idx) {
      this.detail = {
        show: true,
        data: this.roomSolution.classes[idx],
      };
    },
    handleRight() {
      if (this.end < this.display.length) {
        this.start += this.columns;
        this.end += this.columns;
      }
      this.handleArrows();
    },

    handleLeft() {
      if (this.start) {
        this.start -= this.columns;
        this.end -= this.columns;
      }
      this.handleArrows();
    },

    // Update  number of columns and restart the start and end variables
    handleChangedWidth() {
      //if width is < 500 (mobile), these presets will happen only one time.
      if (!this.isMobile) {
        if (this.width <= 1100)
          this.columns = Math.floor(this.$el.clientWidth / 200);
        else this.columns = Math.floor(this.$el.clientWidth / 275);
      } else if (this.isMobile && this.end != 1) {
        this.columns = 1;
      }
      this.start = 0;
      this.end = this.columns;
      this.handleArrows();
    },

    handleArrows() {
      this.disableLeft = this.start == 0 ? true : false;
      this.disableRight =
        this.end >= this.display.length ? true : false;
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width < 570;
    },
    width() {
      return this.$vuetify.breakpoint.width;
    },
  },
  watch: {
    width(e, i) {
      console.log({ e, i, is: this.isMobile });
      this.handleChangedWidth();
    },
  },
  mounted() {
    //create an empty matrix 6x16
    let matrix = [[], [], [], [], [], []];
    for (let i = 0; i < 6; i++) {
      for (let j = 0; j < 16; j++) {
        matrix[i].push({
          codigo: "",
          nome: "",
          id: "",
          idx: "",
          docente: "",
        });
      }
    }

    this.roomSolution.classes.forEach((classObj, idx) => {
      classObj.horario.split(" ").forEach((horario) => {
        //converts UFPB time format to array
        //example '24T23' -> [[2,4], [8,9]]
        let timeMatrix = [[], []];
        let changeCol = 0;
        let turno = 0;

        for (let i = 0; i < horario.length; i++) {
          if (isNaN(Number(horario.charAt(i)))) {
            changeCol = 1;
            switch (horario.charAt(i).toUpperCase()) {
              case "M":
                turno = 0;
                break;
              case "T":
                turno = 6;
                break;
              case "N":
                turno = 12;
                break;
            }
          } else
            timeMatrix[changeCol].push(
              turno + Number(horario.charAt(i))
            );
        }

        //now add classes to main matrix
        timeMatrix[0].forEach((col) => {
          timeMatrix[1].forEach((line) => {
            matrix[col - 2][line - 1] = {
              codigo: classObj.codigo,
              nome: classObj.nome,
              docente: classObj.docente,
              id: classObj.codigo + "-" + classObj.turma,
              idx: idx,
            };
          });
        });
      });
    });

    let display_placeholder = [[], [], [], [], [], []];
    matrix.forEach((col, idx) => {
      let id = "";
      let count = 0;
      col.forEach((line, i) => {
        if (line.id == id) {
          count++;
        } else if (i < col.length - 1) {
          //console.log(line.nome)
          id = line.id;
          if (i > 0)
            display_placeholder[idx].push({
              ...col[i - 1],
              repeat: count,
            });
          count = 1;
        }
        if (i == col.length - 1)
          display_placeholder[idx].push({ ...line, repeat: count });
      });
    });

    [
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sábado",
    ].forEach((day, idx) => {
      this.display.push({
        title: day,
        data: display_placeholder[idx],
      });
    });

    this.handleChangedWidth();
  },
};
</script>

<style scoped>
.teste {
  display: flex;
  background: linear-gradient(
    90deg,
    rgba(71, 118, 230, 1) 40%,
    white 0%
  );
}

.main {
  clear: both;
  width: 100%;
  /* background-image: url('../../assets/saci-pattern-opacity.png');
    background-repeat: repeat; */
}

.mt-17 {
  margin-top: 300px;
}

.icon-enable {
  color: var(--v-primary-base);
}

.icon-disable {
  color: var(--v-accent-darken3);
}

.mt {
  margin-top: 40px;
}

.m-title {
  font-size: 28px;
  margin-left: 150px;
}

.m-subtitle {
  font-size: 18px;
}

.detail-title {
  font-size: 100%;
  word-break: keep-all;
}

.div-title {
  width: 40%;
  float: left;
  color: #eaeaea;
  background: rgb(71, 118, 230);
  /* background: linear-gradient(50deg, rgba(71, 118, 230, 1) 0%, rgba(142, 84, 233, 1) 100%); */
  background: linear-gradient(
    90deg,
    rgba(20, 145, 255, 1) 0%,
    50deg,
    rgba(71, 118, 230, 1)
  );
  /* background: linear-gradient(298deg, rgba(38,115,182,1) 0%, rgba(0,74,198,1) 100%); */
}

.horario {
  background: rgb(71, 118, 230);
  /* background: linear-gradient(50deg, rgba(71, 118, 230, 1) 0%, rgba(142, 84, 233, 1) 100%); */
  background: linear-gradient(
    90deg,
    rgba(20, 145, 255, 1) 0%,
    50deg,
    rgba(71, 118, 230, 1)
  );
  width: 80px;
  color: #eaeaea;
  text-align: center;
  border-radius: 10px;
}

.div-content {
  background-color: white;
  width: 60%;
  float: left;
  min-height: 200px;
}

.ocupacao {
  float: right;
  bottom: 0;
  background: rgb(71, 118, 230);
  /* background: linear-gradient(50deg, rgba(71, 118, 230, 1) 0%, rgba(142, 84, 233, 1) 100%); */
  background: linear-gradient(
    90deg,
    rgba(20, 145, 255, 1) 0%,
    50deg,
    rgba(71, 118, 230, 1)
  );
  height: 64px;
  width: 64px;
  color: #eaeaea;
  border-radius: 32px;
  text-align: center;
  margin: -40px 10px 5px 0;
}

.ocupacao-taxa {
  font-size: 20px;
  margin: 12px 0 -10px 0;
}

.ocupacao-title {
  margin: 0;
  font-size: 10px;
}

@media only screen and (max-width: 500px) {
  .mg-m {
    padding: 0;
  }

  .mt {
    margin-top: 28px;
  }

  .m-title {
    font-size: 22px;
    margin-left: 40px;
  }

  .m-subtitle {
    font-size: 14px;
  }
}
</style>
